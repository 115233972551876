<template>
  <div>
    <v-row>
      <v-col md="6"
             lg="12">
        <DashboardStatusRoom @succes-fresh="resetFresh" :fresh="refreshed"></DashboardStatusRoom>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" lg="12">
        <span class="font-weight-semibold mt-3 mb-3">Afficher le bilan a partir d'un interval de periode</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6"
             lg="4">
        <v-text-field
            v-model="interva_date.start_date"
            dense
            type="date"
            hide-details
            label="Date de création"
            outlined
            placeholder="Date de création"
        ></v-text-field>
      </v-col>
      <v-col md="6"
             lg="4">
        <v-text-field
            v-model="interva_date.end_date"
            dense
            type="date"
            hide-details
            label="Date de création"
            outlined
            placeholder="Date de création"
        ></v-text-field>
      </v-col>
      <v-col md="6"
             lg="4">
        <v-btn
            color="primary"
            @click="newRepportInterval"
        >
          Afficher
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="12"
      >
        <span class="font-weight-semibold mt-3 mb-3">Recette du {{interva_date.start_date}} au {{interva_date.end_date}} par type de chambre (FCFA)</span>
        <v-spacer></v-spacer>
        <RoomPieChart @succes-pie-update="resetPie()" :fresh="refreshed_one"></RoomPieChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="12"
      >
        <RoomLineChart @succes-stayline-update="resetupdateLine()" :fresh="refreshed_two"></RoomLineChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="12"
      >
        <span class="font-weight-semibold mt-3 mb-3">Point de caisse du {{interva_date.start_date}} au {{interva_date.end_date}} (montant total : {{repport1}} FCFA)</span>
        <StayLineChart @succes-stayline-one-update="resetupdateLineOne()" :fresh="refreshed_three"></StayLineChart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import RoomPieChart from './RoomPieChart'
// eslint-disable-next-line import/extensions
import RoomLineChart from './RoomLineChart'
// eslint-disable-next-line import/extensions
import StayLineChart from './StayLineChart'
// eslint-disable-next-line import/extensions,import/no-unresolved

import DashboardStatusRoom from '@/views/repport/DashboardStatusRoom'
import * as components from 'vuetify'

export default {
  name: 'Repport',
  components: {
    DashboardStatusRoom,
    RoomPieChart,
    RoomLineChart,
    StayLineChart,
  },
  data() {
    return {
      refreshed:false,
      refreshed_one:false,
      refreshed_two:false,
      refreshed_three:false,
      interva_date: {
        start_date:'',
        end_date: '',
      },
      repport: {},
      repport1: 0,
      totalProfit: {
        statTitle: 'Personnels',
        icon: this.mdiChartBar,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      },
    }
  },
  mounted() {
    this.Repport()
  },
  methods: {
    async Repport() {
      await this.$axios.get(this.$endpoint.LoadRepportMonth).then(rp => {
        // eslint-disable-next-line no-return-assign
        localStorage.setItem('dataRepport', JSON.stringify(rp.data))
        this.interva_date.start_date = new Date(rp.data.start_month).toLocaleDateString('en-US')
        this.interva_date.end_date = new Date(rp.data.end_month).toLocaleDateString('en-US')
        this.repport1 =rp.data.stay_hebdo.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
        // console.log(this.repport)
      })
    },
    async newRepportInterval() {

      if (this.interva_date.start_date !== '' && this.interva_date.end_date !== ''){
        await this.$axios.post(this.$endpoint.LoadRepportInterval, this.interva_date).then(rp => {
          // eslint-disable-next-line no-return-assign
          localStorage.setItem('dataRepport', JSON.stringify(rp.data))
          this.interva_date.start_date = new Date(rp.data.start_month).toLocaleDateString('en-US')
          this.interva_date.end_date = new Date(rp.data.end_month).toLocaleDateString('en-US')
          this.repport1 =rp.data.stay_hebdo.map(item => item.stays_total_price_to_pay).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
          this.refreshed = true
          this.refreshed_one = true
          this.refreshed_two = true
          this.refreshed_three = true
          // console.log(this.repport)
        })
      }
    },
    resetFresh() {
     // alert('actualize')
      this.refreshed =false
    },
    resetPie() {
     // alert('actualize')
      this.refreshed_one =false
    },
    resetupdateLine() {
     // alert('actualize')
      this.refreshed_two =false
    },
    resetupdateLineOne() {
     // alert('actualize')
      this.refreshed_three =false
    },
  },
}
</script>

<style scoped>

</style>
