var render, staticRenderFns
import script from "./RoomPieChart.vue?vue&type=script&lang=js&"
export * from "./RoomPieChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6f1609",
  null
  
)

export default component.exports